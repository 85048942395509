<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: "es",
  },
});
const emit = defineEmits(["update:modelValue"]);
const language = computed({
  get() {
    return props.modelValue;
  },
  set(value: string) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <USelectMenu
    v-model="language"
    :options="LANGUAGES"
    option-attribute="name"
    value-attribute="code"
    :ui="{
      base: 'font-medium',
    }"
  >
    <template #option="{ option }">
      <Icon :name="option.flag" />
      {{ option.name }}
    </template>
  </USelectMenu>
</template>
